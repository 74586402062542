<template>
  <v-card max-width="550" class="mx-auto mb-5 py-10 px-3 px-md-8">
    <div class="d-flex align-start justify-space-between mb-10">
      <v-col cols="11" class="d-flex pa-0">
        <div class="me-2">
          <img
            v-if="business.logo"
            :src="businessLogo"
            width="42"
            height="42"
          />

          <div v-else class="logo-bg d-flex align-center justify-center">
            <v-icon size="24" color="gray4">$shop</v-icon>
          </div>
        </div>

        <v-col cols="10" sm="11" class="pa-0">
          <div class="font-medium-16 gray13--text">
            {{ business.businessName }}
          </div>

          <div class="font-regular-12 gray7--text">
            {{ business.businessType.businessTypeName }}
          </div>
        </v-col>
      </v-col>

      <router-link
        :to="`/panel/businesses/${business.id}/details`"
        class="mt-1"
      >
        <v-icon size="20" color="gray8">$edit</v-icon>
      </router-link>
    </div>

    <div
      v-for="(gateway, index) in business.gateways"
      :key="gateway.id"
      class="ps-5 py-3"
    >
      <router-link
        :to="
          gateway.gatewayType === 'WEB'
            ? `/panel/businesses/web-gateway-settings/${gateway.id}`
            : `/panel/businesses/pos-gateway-settings/${gateway.id}`
        "
        class="d-block d-flex align-center justify-space-between mb-6 text-decoration-none"
      >
        <div>
          <v-icon v-if="gateway.gatewayType === 'WEB'" size="24" class="me-2"
            >$browser</v-icon
          >

          <v-icon v-else size="24" class="me-2">$pos</v-icon>

          <span class="gray11--text">
            {{
              gateway.gatewayType === "WEB"
                ? $t("panel.businessesPage.businessesList.webGatewaySettings")
                : $t("panel.businessesPage.businessesList.posGatewaySettings")
            }}
          </span>
        </div>

        <div>
          <v-chip
            v-if="
              gateway.gatewayType === 'POS' &&
              gateway.gatewayStatus === 'PENDING'
            "
            small
            color="infoBg"
            text-color="info"
            class="me-2"
          >
            <span class="font-regular-10">{{ gateway.gatewayStatus }}</span>
          </v-chip>
          <v-icon size="24" color="gray5">$arrow</v-icon>
        </div>
      </router-link>

      <v-divider
        v-if="
          business.gateways.length < 2 || index !== business.gateways.length - 1
        "
      ></v-divider>

      <router-link
        v-if="
          business.gateways.length < 2 &&
          gateway &&
          gateway.gatewayType === 'WEB'
        "
        :to="`/panel/businesses/${business.id}/create-pos-gateway`"
        class="d-block mt-6"
      >
        <v-icon size="24" color="primary" class="me-2">$add</v-icon>
        <span class="font-semiBold-14">
          {{ $t("panel.businessesPage.businessesList.addPosGateway") }}
        </span>
      </router-link>

      <router-link
        v-else-if="
          business.gateways.length < 2 &&
          gateway &&
          gateway.gatewayType === 'POS'
        "
        :to="`/panel/businesses/${business.id}/create-web-gateway`"
        class="d-block mt-6"
      >
        <v-icon size="24" color="primary" class="me-2">$add</v-icon>
        <span class="font-semiBold-14">
          {{ $t("panel.businessesPage.businessesList.addWebGateway") }}
        </span>
      </router-link>
    </div>

    <div
      v-if="!business.gateways || !business.gateways.length"
      class="ps-5 py-3"
    >
      <router-link
        :to="`/panel/businesses/${business.id}/create-web-gateway`"
        class="d-block mb-6"
      >
        <v-icon size="24" color="primary" class="me-2">$add</v-icon>
        <span class="font-semiBold-14">
          {{ $t("panel.businessesPage.businessesList.addWebGateway") }}
        </span>
      </router-link>

      <v-divider></v-divider>

      <router-link
        :to="`/panel/businesses/${business.id}/create-pos-gateway`"
        class="d-block mt-6"
      >
        <v-icon size="24" color="primary" class="me-2">$add</v-icon>
        <span class="font-semiBold-14">
          {{ $t("panel.businessesPage.businessesList.addPosGateway") }}
        </span>
      </router-link>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "business-card",
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      businessLogo: "data:image;base64," + this.business.logo,
    };
  },
};
</script>

<style scoped lang="scss">
@import "BusinessCard";
</style>
