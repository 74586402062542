<template>
  <v-row v-if="loading" align="center" justify="center" class="fill-height">
    <v-progress-circular
      :size="60"
      :width="5"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-row>

  <v-container v-else :class="{ 'fill-height': !businesses.allItems.length }">
    <business-card
      v-for="business in businesses.allItems"
      :key="business.id"
      :business="business"
    />

    <v-progress-circular
      v-if="businesses.moreItemsLoading"
      :size="20"
      :width="2"
      color="primary"
      indeterminate
      class="d-block mx-auto mb-4"
    />

    <div v-if="businesses.allItems.length" id="addNew">
      <router-link
        to="/panel/businesses/create"
        class="add-button d-flex align-center justify-center mx-auto text-decoration-none"
      >
        <v-icon size="22" color="gray8" class="me-2">$add</v-icon>
        <span class="gray8--text">
          {{ $t("panel.businessesPage.businessesList.addNew") }}
        </span>
      </router-link>
      <div
        v-intersect.quiet="intersectBusinessesList"
        v-if="!businesses.moreItemsLoading && businesses.displayMoreItem"
        class="mt-16"
      />
    </div>

    <div v-else class="text-center mx-auto">
      <div>
        <v-icon size="62" color="gray5" class="mb-4">$shopInPage</v-icon>
      </div>

      <div class="font-regular-16 gray7--text mb-4">
        {{ $t("panel.businessesPage.businessesList.noBusinesses") }}
      </div>

      <v-btn
        color="primary"
        depressed
        to="/panel/businesses/create"
        id="addBusiness"
      >
        <span class="font-semiBold-14">
          {{ $t("panel.businessesPage.businessesList.addBusiness") }}
        </span>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { MerchantService } from "@/services";
import BusinessCard from "./BusinessCard";

export default {
  name: "businesses",
  components: { BusinessCard },
  data() {
    return {
      loading: false,
      businesses: {
        page: 1,
        size: 30,
        displayMoreItem: false,
        moreItemsLoading: false,
        allItems: [],
      },
    };
  },
  mounted() {
    this.getAllMerchants();
  },
  methods: {
    intersectBusinessesList() {
      this.businesses.page++;
      this.businesses.moreItemsLoading = true;
      MerchantService.getAllMerchants({
        page: this.businesses.page,
        size: this.businesses.size,
      })
        .then((response) => {
          const data = response.data.data;
          const totalElements = data.totalElements;
          const items = data.content;
          this.businesses.allItems = this.businesses.allItems.concat(items);
          this.businesses.displayMoreItem =
            totalElements !== this.businesses.allItems.length;
        })
        .finally(() => {
          this.businesses.moreItemsLoading = false;
        });
    },
    getAllMerchants() {
      this.loading = true;
      MerchantService.getAllMerchants({
        page: this.businesses.page,
        size: this.businesses.size,
      }).then((res) => {
        const data = res.data.data;
        const totalElements = data.totalElements;
        this.businesses.allItems = data.content;
        this.businesses.displayMoreItem =
          totalElements !== this.businesses.allItems.length;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "Businesses";
</style>
