var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto mb-5 py-10 px-3 px-md-8",attrs:{"max-width":"550"}},[_c('div',{staticClass:"d-flex align-start justify-space-between mb-10"},[_c('v-col',{staticClass:"d-flex pa-0",attrs:{"cols":"11"}},[_c('div',{staticClass:"me-2"},[(_vm.business.logo)?_c('img',{attrs:{"src":_vm.businessLogo,"width":"42","height":"42"}}):_c('div',{staticClass:"logo-bg d-flex align-center justify-center"},[_c('v-icon',{attrs:{"size":"24","color":"gray4"}},[_vm._v("$shop")])],1)]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"10","sm":"11"}},[_c('div',{staticClass:"font-medium-16 gray13--text"},[_vm._v(" "+_vm._s(_vm.business.businessName)+" ")]),_c('div',{staticClass:"font-regular-12 gray7--text"},[_vm._v(" "+_vm._s(_vm.business.businessType.businessTypeName)+" ")])])],1),_c('router-link',{staticClass:"mt-1",attrs:{"to":`/panel/businesses/${_vm.business.id}/details`}},[_c('v-icon',{attrs:{"size":"20","color":"gray8"}},[_vm._v("$edit")])],1)],1),_vm._l((_vm.business.gateways),function(gateway,index){return _c('div',{key:gateway.id,staticClass:"ps-5 py-3"},[_c('router-link',{staticClass:"d-block d-flex align-center justify-space-between mb-6 text-decoration-none",attrs:{"to":gateway.gatewayType === 'WEB'
          ? `/panel/businesses/web-gateway-settings/${gateway.id}`
          : `/panel/businesses/pos-gateway-settings/${gateway.id}`}},[_c('div',[(gateway.gatewayType === 'WEB')?_c('v-icon',{staticClass:"me-2",attrs:{"size":"24"}},[_vm._v("$browser")]):_c('v-icon',{staticClass:"me-2",attrs:{"size":"24"}},[_vm._v("$pos")]),_c('span',{staticClass:"gray11--text"},[_vm._v(" "+_vm._s(gateway.gatewayType === "WEB" ? _vm.$t("panel.businessesPage.businessesList.webGatewaySettings") : _vm.$t("panel.businessesPage.businessesList.posGatewaySettings"))+" ")])],1),_c('div',[(
            gateway.gatewayType === 'POS' &&
            gateway.gatewayStatus === 'PENDING'
          )?_c('v-chip',{staticClass:"me-2",attrs:{"small":"","color":"infoBg","text-color":"info"}},[_c('span',{staticClass:"font-regular-10"},[_vm._v(_vm._s(gateway.gatewayStatus))])]):_vm._e(),_c('v-icon',{attrs:{"size":"24","color":"gray5"}},[_vm._v("$arrow")])],1)]),(
        _vm.business.gateways.length < 2 || index !== _vm.business.gateways.length - 1
      )?_c('v-divider'):_vm._e(),(
        _vm.business.gateways.length < 2 &&
        gateway &&
        gateway.gatewayType === 'WEB'
      )?_c('router-link',{staticClass:"d-block mt-6",attrs:{"to":`/panel/businesses/${_vm.business.id}/create-pos-gateway`}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"24","color":"primary"}},[_vm._v("$add")]),_c('span',{staticClass:"font-semiBold-14"},[_vm._v(" "+_vm._s(_vm.$t("panel.businessesPage.businessesList.addPosGateway"))+" ")])],1):(
        _vm.business.gateways.length < 2 &&
        gateway &&
        gateway.gatewayType === 'POS'
      )?_c('router-link',{staticClass:"d-block mt-6",attrs:{"to":`/panel/businesses/${_vm.business.id}/create-web-gateway`}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"24","color":"primary"}},[_vm._v("$add")]),_c('span',{staticClass:"font-semiBold-14"},[_vm._v(" "+_vm._s(_vm.$t("panel.businessesPage.businessesList.addWebGateway"))+" ")])],1):_vm._e()],1)}),(!_vm.business.gateways || !_vm.business.gateways.length)?_c('div',{staticClass:"ps-5 py-3"},[_c('router-link',{staticClass:"d-block mb-6",attrs:{"to":`/panel/businesses/${_vm.business.id}/create-web-gateway`}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"24","color":"primary"}},[_vm._v("$add")]),_c('span',{staticClass:"font-semiBold-14"},[_vm._v(" "+_vm._s(_vm.$t("panel.businessesPage.businessesList.addWebGateway"))+" ")])],1),_c('v-divider'),_c('router-link',{staticClass:"d-block mt-6",attrs:{"to":`/panel/businesses/${_vm.business.id}/create-pos-gateway`}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"24","color":"primary"}},[_vm._v("$add")]),_c('span',{staticClass:"font-semiBold-14"},[_vm._v(" "+_vm._s(_vm.$t("panel.businessesPage.businessesList.addPosGateway"))+" ")])],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }